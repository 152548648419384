ul, ol, li, h1, h2, h3, h4, h5, h6 {margin: 0; padding:0; list-style-type: none;}
.zIndex-150 {
  z-index: 150 !important;
}
.zIndex-200 {
  z-index: 200 !important;
}
.zIndex-250 {
  z-index: 250 !important;
}
.zIndex-300 {
  z-index: 300 !important;
}
.zIndex-350 {
  z-index: 350 !important;
}
.upperElement {
  position: relative;
  z-index: 999;
  &.overflowVisible {
    overflow: visible !important;
  }
}
.onboardPopup {
  width: 225px;
  height: auto;
  border: 1px solid #C0C3CE;
  background: #fff;
  text-align: left;
  box-shadow: 0px 0px 10px rgba(131, 136, 158, 0.1);
  border-radius: 3px;
  padding: 15px;
  position: absolute;
  right: auto;
  left: -232px;
  bottom: auto;
  top: 0;
  h3{
    color: #000;
    font-size: 14px;
    font-weight: 500;
    max-width: 90%;
    margin-bottom: 8px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: #83889E;
  }
  .closeBtn {
    width: 15px;
    height: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 10px;
    em {
      color: #D0D2DA !important;
      font-size: 9px !important;
    }
  }
  .onboardPopupFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 12px;
      font-weight: 400;
      color: #83889E;
    }
    button {
      background: #fff;
      border: 1px solid #9F4DFF;
      border-radius: 3px;
      color: #9F4DFF;
      font-size: 12px;
      font-weight: 500;
      outline: none;
      padding: 4px 12px;
    }
  }
}

